<template>
  <div
    v-if="balance !== null"
    ref="Display"
    class="relative transition-all duration-1000"
    :class="{
      'animate-scale-up-once' : animDone && balanceChangeDirection === 1,
      'animate-slide-down-fade-in-fast' : animDone && balanceChangeDirection === -1,
    }"
    :style="displayWidth ? `min-width: ${displayWidth}px;` : `width: auto; min-width: 10px;`"
  >
    {{ formattedBalance }}
  </div>
</template>

<script setup>
import av from 'animate-value';
import { storeToRefs } from 'pinia';

import { useBankingStore } from '@/store/banking';

const props = defineProps({
  balance: {
    type: Object,
    required: true,
  },
  maxDecimals: {
    type: Number,
    default: 6,
  },
});

const { $truncateNumber, } = useNuxtApp();
const bankingStore = useBankingStore();
const {
  balanceUpdateDuration,
} = storeToRefs(bankingStore);

const balanceDisplay = ref(null);
const animDone = ref(false);
const displayWidth = ref(null);
const balanceChangeDirection = ref(0);
const Display = ref();

const balanceValue = computed(() => props.balance?.value || 0);
const formattedBalance = computed(() => balanceDisplay.value === 0 ? '0.00' : balanceDisplay.value);

convertBalance(balanceValue.value, null);

function convertBalance(newVal, oldVal) {
  if (newVal === null) {
    balanceDisplay.value = null;
    return;
  }

  if (oldVal === null) {
    balanceDisplay.value = $truncateNumber(newVal, props.maxDecimals, false);
    return;
  }

  newVal = $truncateNumber(newVal, props.maxDecimals);
  oldVal = $truncateNumber(oldVal, props.maxDecimals);

  const oldDisplayWidth = Display.value?.clientWidth;
  oldDisplayWidth < 50 ? (displayWidth.value = 50) : (displayWidth.value = oldDisplayWidth);

  // Reducing balance should be instant. Adding to it should animate.
  balanceChangeDirection.value = 0;

  if (newVal < oldVal) {
    balanceChangeDirection.value = -1;
  } else if (newVal > oldVal) {
    balanceChangeDirection.value = 1;
  }

  if (newVal <= oldVal) {
    balanceUpdateDuration.value = 0;
  }

  av({
    from: Number(oldVal),
    to: Number(newVal),
    duration: balanceUpdateDuration.value * 1000,
    easing: 'linear',
    change: (value) => {
      balanceDisplay.value = $truncateNumber(value, props.maxDecimals, false);
    },
    done: () => {
      animDone.value = true;
      displayWidth.value = null;
      setTimeout(() => {
        animDone.value = false;
      }, 500);
    },
  });
}

watch(
  () => balanceValue.value,
  (newVal, oldVal) => {
    convertBalance(newVal, oldVal);
  }
);
</script>
